var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    plc_monitor: _vm.tabIndex == 1 || _vm.tabIndex == 3,
    plc_th_status: _vm.tabIndex == 0 || _vm.tabIndex == 2,
    plc_metal_status: _vm.tabIndex == 1,
    burner: _vm.tabIndex == 2,
    burner_molding: _vm.tabIndex == 3,
    all_status: _vm.$screen.width > 1280 ? _vm.tabIndex == 4 : '',
    plc_th_log: _vm.$screen.width > 1280 ? _vm.tabIndex == 5 : _vm.tabIndex == 4,
    burner_status:
      _vm.$screen.width > 1280
        ? _vm.tabIndex == 5 || _vm.tabIndex == 6 || _vm.tabIndex == 7
        : _vm.tabIndex == 4 || _vm.tabIndex == 5 || _vm.tabIndex == 6,
    default_setting: _vm.$screen.width > 1280 ? _vm.tabIndex == 8 : _vm.tabIndex == 7,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToPlcMonitorPage',"options":_vm.asideIndex.filter(function (x) { return x.name != 'all_status'; })}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',_vm._l((_vm.asideIndex),function(aside,index){return _c('li',{key:index,class:{ active: _vm.tabIndex == index }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(index)}}},[_vm._v(_vm._s(aside.title))])])}),0)]):_vm._e(),(_vm.tabIndex == 0)?_c('TempRealtimeForm'):_vm._e(),(_vm.tabIndex == 1)?_c('CounterRealtimeForm'):_vm._e(),(_vm.tabIndex == 2)?_c('BurnerForm'):_vm._e(),(_vm.tabIndex == 3)?_c('BurnerMoldingForm'):_vm._e(),(_vm.$screen.width > 1280 && _vm.tabIndex == 4)?_c('AllPlcStatusForm'):_vm._e(),(_vm.$screen.width > 1280 ? _vm.tabIndex == 5 : _vm.tabIndex == 4)?_c('TempLogForm'):_vm._e(),(_vm.$screen.width > 1280 ? _vm.tabIndex == 6 : _vm.tabIndex == 5)?_c('CounterLogForm'):_vm._e(),(_vm.$screen.width > 1280 ? _vm.tabIndex == 7 : _vm.tabIndex == 6)?_c('BurnerStatusForm'):_vm._e(),(_vm.$screen.width > 1280 ? _vm.tabIndex == 8 : _vm.tabIndex == 7)?_c('DefaultPlcForm'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }