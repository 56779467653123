<template>
  <div
    id="contents"
    :class="{
      plc_monitor: tabIndex == 1 || tabIndex == 3,
      plc_th_status: tabIndex == 0 || tabIndex == 2,
      plc_metal_status: tabIndex == 1,
      burner: tabIndex == 2,
      burner_molding: tabIndex == 3,
      all_status: $screen.width > 1280 ? tabIndex == 4 : '',
      plc_th_log: $screen.width > 1280 ? tabIndex == 5 : tabIndex == 4,
      burner_status:
        $screen.width > 1280
          ? tabIndex == 5 || tabIndex == 6 || tabIndex == 7
          : tabIndex == 4 || tabIndex == 5 || tabIndex == 6,
      default_setting: $screen.width > 1280 ? tabIndex == 8 : tabIndex == 7,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="asideIndex.filter(x => x.name != 'all_status')"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(aside, index) in asideIndex"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a @click="SetOpenTabIndex(index)">{{ aside.title }}</a>
          </li>
        </ul>
      </div>
      <TempRealtimeForm v-if="tabIndex == 0" />
      <CounterRealtimeForm v-if="tabIndex == 1" />
      <BurnerForm v-if="tabIndex == 2" />
      <BurnerMoldingForm v-if="tabIndex == 3" />
      <AllPlcStatusForm v-if="$screen.width > 1280 && tabIndex == 4" />
      <TempLogForm
        v-if="$screen.width > 1280 ? tabIndex == 5 : tabIndex == 4"
      />
      <CounterLogForm
        v-if="$screen.width > 1280 ? tabIndex == 6 : tabIndex == 5"
      />
      <BurnerStatusForm
        v-if="$screen.width > 1280 ? tabIndex == 7 : tabIndex == 6"
      />
      <DefaultPlcForm
        v-if="$screen.width > 1280 ? tabIndex == 8 : tabIndex == 7"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import TempRealtimeForm from '@/views/forms/Custom/Monitor/21/TempRealtimeForm';
import CounterRealtimeForm from '@/views/forms/Custom/Monitor/21/CounterRealtimeForm';
import BurnerForm from '@/views/forms/Custom/Monitor/21/BurnerForm';
import TempLogForm from '@/views/forms/Custom/Monitor/21/TempLogForm';
import CounterLogForm from '@/views/forms/Custom/Monitor/21/CounterLogForm';
import BurnerStatusForm from '@/views/forms/Custom/Monitor/21/BurnerStatusForm';
import BurnerMoldingForm from '@/views/forms/Custom/Monitor/21/BurnerMoldingForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/21/DefaultPlcForm';
import AllPlcStatusForm from '@/views/forms/Custom/Monitor/21/AllPlcStatusForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    TempRealtimeForm,
    CounterRealtimeForm,
    BurnerForm,
    BurnerMoldingForm,
    TempLogForm,
    BurnerStatusForm,
    CounterLogForm,
    AllPlcStatusForm,
    DefaultPlcForm,
  },
  data() {
    return {
      asideIndex: [
        { title: '실시간 온습도 현황', name: 'real_time_status' },
        { title: '실시간 금속검출기 현황', name: 'counter_time_status' },
        { title: '실시간 가열기 현황', name: 'burner' },
        { title: '실시간 가열성형기 현황', name: 'burner_molding' },
        { title: '실시간 PLC 현황', name: 'all_status' },
        { title: '온습도 현황', name: 'temp_log_status' },
        { title: '금속검출기 현황', name: 'counter_status' },
        { title: '가열성형기/가열기 현황', name: 'burner_status' },
        { title: '기준값 설정', name: 'default_setting' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
    if (this.$route.meta.tab != undefined) {
      this.$store.commit(
        'setOpenTabIndexToPlcMonitorPage',
        this.$route.meta.tab,
      );
      delete this.$route.meta.tab;
    }
  },
};
</script>

<style lang="scss">
@import 'daejin_monitoring';
</style>
