<template>
  <div class="article">
    <div class="tbl_wrap" @scroll="realTimeScroll($event)">
      <button
        type="button"
        @click="scrollTop()"
        v-show="scrollHeight > 2000"
      ></button>
      <div class="tbl_option">
        <div>
          <div class="input_text">
            <label>가열성형기/가열기 구분</label>
            <my-selectric
              v-if="sensorDetailTypeSelectric"
              :id="'sensorDetailTypeSelectric'"
              :watch="searchSensorDetailType"
              :options="filterBunner"
              :commit="'setSearchSensorDetailType'"
            >
            </my-selectric>
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>조회 날짜</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="selectDate"
              :max="maxDate"
              @change="setDate($event)"
            />
          </div>
          <div class="input_text">
            <label>조회 시간</label>
            <input
              id="startTime"
              type="time"
              min="00:00"
              max="23:00"
              :value="startTime"
              @change="setTime($event)"
            />
            <p>~</p>
            <input
              id="endTime"
              type="time"
              min="01:00"
              max="24:00"
              :value="endTime"
              @change="setTime($event)"
            />
          </div>
          <button class="btn_sub2" @click="searchLog()">
            검색
          </button>
        </div>
        <div>
          <button class="excel_btn" @click="exportExcel">
            엑셀로 출력
          </button>
        </div>
      </div>
      <h6>조회수 : {{ plc_sensor_logs_time.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="5">
                가열성형기/가열기 현황
              </th>
            </tr>
            <tr>
              <th>조회시간</th>
              <th>가열성형기/가열기명</th>
              <th>품온 / 가열온도 / RPM</th>
              <th>값</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in plc_sensor_logs_time"
              :key="item.id"
              :class="{
                flow: isOverFlowValue(item) || isUnderFlowValue(item),
              }"
            >
              <td>{{ item.create_time | formatDateHHMMSS }}</td>
              <td>
                {{ item.plc_sensor_name.split('인터페이스')[0] }}
              </td>
              <td>
                {{ item.plc_sensor_detail_type_name }}
              </td>
              <td>
                {{
                  item.plc_sensor_detail_type_name.includes('RPM')
                    ? item.value
                    : item.value + '℃'
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <infinite-loading
          v-if="pageIndex != 0"
          :key="rerend"
          @infinite="infiniteHandler"
          spinner="bubbles"
        ></infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import {
  yyyymmdd,
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
} from '@/utils/func';
import { formatDateHHMMSS } from '@/utils/filters';
import fetchMixin from '@/mixins/fetch';
import MySelectric from '@/layouts/components/MySelectric';
import SPINNERMIXIN from '@/mixins/spinner';

import InfiniteLoading from 'vue-infinite-loading';

export default {
  mixins: [fetchMixin, SPINNERMIXIN],
  components: {
    MySelectric,
    InfiniteLoading,
  },
  data() {
    return {
      maxDate: yyyymmdd(new Date()),
      sensorDetailTypeSelectric: false,
      scrollChk: false,
      rerend: 0,
      scrollHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs_time: 'getPlcSensorLogTime',

      selectDate: 'getSelectDateFromPlcSensorLog',
      startTime: 'getStartTimeFromPlcSensorLog',
      endTime: 'getEndTimeFromPlcSensorLog',
      searchSensorDetailType: 'getSearchSensorDetailType',
      pageIndex: 'getPageIndexFromPlcSensorLog',

      plc_sensors: 'getPlcSensor',

      scrollDate: 'getScrollDateFromPlcSensorLog',
      scrollStartTime: 'getScrollStartTimeFromPlcSensorLog',
      scrollEndTime: 'getScrollEndTimeFromPlcSensorLog',
    }),

    filterBunner() {
      let sensorTypeOptions = [];

      sensorTypeOptions.push({
        label: '가열성형기/가열기 전체',
        value: 0,
        detail: null,
      });

      const type = this.plc_sensors.filter(
        x =>
          (x.plc_sensor_type_id == 6 && x.plc_sensor_detail_type_id == 8) ||
          (x.plc_sensor_type_id == 7 && x.plc_sensor_detail_type_id == 10),
      );
      type.forEach(el => {
        sensorTypeOptions.push({
          label: el.name.split('인터페이스')[0],
          value: el.id,
          detail: el.name.split('인터페이스')[0],
        });
      });
      return sensorTypeOptions;
    },
  },
  methods: {
    isOverFlowValue(item) {
      const found = this.plc_sensors.find(
        x => x.plc_sensor_detail_type_id == item.plc_sensor_detail_type_id,
      );
      if (found != undefined && found.max_value != null) {
        if (found.max_value < item.value) {
          console.log('isOverFlowValue is True');
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    scrollTop() {
      const scrT = document.querySelector('.tbl_wrap');
      scrT.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      // this.visible = this.scrT.scrollTop > 1500 ? true : false;
    },
    realTimeScroll(e) {
      this.scrollHeight = e.target.scrollTop;
    },
    isUnderFlowValue(item) {
      const found = this.plc_sensors.find(
        x => x.plc_sensor_detail_type_id == item.plc_sensor_detail_type_id,
      );
      if (found != undefined && found.min_value != null) {
        if (found.min_value > item.value) {
          console.log('isUnderFlowValue is True');
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    setDate(e) {
      this.$store.commit('setSelectDateToPlcSensorLog', e.target.value);
    },
    setTime(e) {
      if (e.target.id == 'startTime') {
        this.$store.commit('setStartTimeToPlcSensorLog', e.target.value);
      } else {
        this.$store.commit('setEndTimeToPlcSensorLog', e.target.value);
      }
    },
    async searchLog() {
      this.$store.commit('resetPlcSensorLogTime');
      this.$store.commit('setPageIndexToPlcSensorLog', 0);
      this.$store.commit(
        'setScrollDateToPlcSensorLog',
        this.lodash.clonedeep(this.selectDate),
      );
      this.$store.commit(
        'setScrollStartTimeToPlcSensorLog',
        this.lodash.clonedeep(this.startTime),
      );
      this.$store.commit(
        'setScrollEndTimeToPlcSensorLog',
        this.lodash.clonedeep(this.endTime),
      );

      await this.FETCH_WITH_PAYLOAD(
        'FETCH_PLC_SENSOR_LOG_TIME',
        {
          date: this.selectDate,
          start: this.startTime,
          end: this.endTime,
          loction: 3,
          type: 7,
          detailType: this.searchSensorDetailType,
          pageIndex: this.pageIndex,
        },
        '가열성형기/가열기 현황',
      );
      this.$store.commit('setPageIndexToPlcSensorLog', 1);
    },
    async infiniteHandler($state) {
      if (this.pageIndex != 0) {
        const maxLen = 100;
        await this.$store
          .dispatch(
            'FETCH_PLC_SENSOR_LOG_TIME',
            {
              date: this.scrollDate,
              start: this.scrollStartTime,
              end: this.scrollEndTime,
              loction: 3,
              type: 7,
              detailType: this.searchSensorDetailType,
              pageIndex: this.pageIndex,
            },
            '가열성형기/가열기 현황',
          )
          .then(response => {
            setTimeout(() => {
              console.log('@@', response);
              this.$store.commit(
                'setPageIndexToPlcSensorLog',
                this.pageIndex + 1,
              );
              if (response.data.data_list.length) {
                $state.loaded();
                // 끝 지정(No more data) - 데이터가 maxLen개 미만이면
                if (response.data.data_list.length / maxLen < 1) {
                  $state.complete();
                }
              } else {
                // 끝 지정(No more data)
                $state.complete();
              }
            }, 1000);
          })
          .catch(error => {
            console.log(error);
            $state.complete();
          });
      }
    },
    exportExcel() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_PLC_SENSOR_LOG_TIME_EXCEL', {
          date: this.selectDate,
          start: this.startTime,
          end: this.endTime,
          loction: 3,
          type: 7,
          detailType: this.searchSensorDetailType,
        })
        .then(response => {
          const plcData = response.data.data_list;
          let plcList = [];
          const searchSensorName = this.filterBunner.filter(
            x => x.value == this.searchSensorDetailType,
          )[0].label;

          let merge = [
            { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
            { s: { r: 1, c: 1 }, e: { r: 1, c: 3 } },
          ];
          let standardInfo = [
            ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
            [
              '조회 시간',
              yyyy년MM월dd일(this.selectDate) +
                ' (' +
                this.startTime +
                ' ~ ' +
                this.endTime +
                ')',
            ],
            [],
            ['가열성형기/가열기 구분', searchSensorName],
            [],
            [],
            ['조회시간', '가열성형기/가열기명', '품온 / 가열온도 / RPM', '값'],
          ];

          plcList = plcList.concat(standardInfo);

          plcData.forEach(el => {
            const value =
              el.plc_sensor_detail_type_id == 9 ? el.value : el.value + '℃';

            plcList.push([
              formatDateHHMMSS(el.create_time),
              el.plc_sensor_name.substr(0, 6),
              el.plc_sensor_detail_type_name,
              value,
            ]);
          });

          let wb = XLSX.utils.book_new();

          let plcDataTab = XLSX.utils.json_to_sheet(plcList, {
            skipHeader: true,
          });

          plcDataTab['!merges'] = merge;

          XLSX.utils.book_append_sheet(
            wb,
            plcDataTab,
            '가열성형기_가열기 현황',
          );

          let title =
            '가열성형기_가열기 현황 - ' +
            searchSensorName +
            '(' +
            yyyy년MM월dd일(this.selectDate) +
            '_' +
            this.startTime +
            '-' +
            this.endTime +
            ').xls';

          XLSX.writeFile(wb, title);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.selectDate == null) {
      let hours = new Date().getHours();
      let hoursString = String(hours > 9 ? hours : `0${hours}`);
      let minutes = new Date().getMinutes();
      minutes = String(minutes > 9 ? minutes : `0${minutes}`);

      let endTime = hoursString + ':' + minutes;
      this.$store.commit('setEndTimeToPlcSensorLog', endTime);

      hoursString = String(hours - 1 > 9 ? hours - 1 : `0${hours - 1}`);
      let startTime = hoursString + ':' + minutes;
      this.$store.commit('setStartTimeToPlcSensorLog', startTime);
      this.$store.commit('setSelectDateToPlcSensorLog', this.maxDate);
    }

    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }
    this.sensorDetailTypeSelectric = true;
  },
};
</script>

<style lang="scss" scoped></style>
