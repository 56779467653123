<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_option">
      <button
        type="button"
        class="btn_sub1"
        @click.prevent="fullScreen = !fullScreen"
      >
        {{ fullScreen ? '닫기' : '전체화면' }}
      </button>
    </div>
    <div class="card_wrap card_wrap_floor">
      <div class="card_head">
        <h5>실시간 온습도 현황</h5>
      </div>
      <div class="card_body">
        <div>
          <h5>1층</h5>
          <ul class="th_card_wrap">
            <li
              class="th_card"
              v-for="n in filtered_temp_humi_sensor_list.filter(x =>
                x.temp.name.includes('1층'),
              )"
              :key="n.id"
              :class="{
                t_warning: n.temp.warning,
                h_warning: n.humi.warning,
                warning: n.temp.warning && n.humi.warning,
              }"
            >
              <h5>
                {{ n.temp.plc_location_name.split(`-`)[0] }}<br />
                {{
                  `${
                    n.temp.plc_location_name.split(`-`)[1] == undefined
                      ? ''
                      : `(${n.temp.plc_location_name.split(`-`)[1]})`
                  }`
                }}
              </h5>
              <div>
                <p>온도</p>
                <span>{{ n.temp.value }} ℃</span>
              </div>
              <div>
                <p>습도</p>
                <span>{{ n.humi.value }} %</span>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <h5>2층</h5>
          <ul class="th_card_wrap">
            <li
              class="th_card"
              :class="{ warning: item.warning }"
              v-for="(item, index) in filtered_temp_only_sensor_list.filter(x =>
                x.name.includes('2층'),
              )"
              :key="index"
            >
              <h5>
                {{ item.plc_sensor_name.split('온도센서')[0] }}
              </h5>
              <div>
                <p>온도</p>
                <span>{{ item.value }} ℃</span>
              </div>
            </li>
            <li
              class="th_card"
              v-for="n in filtered_temp_humi_sensor_list.filter(x =>
                x.temp.name.includes('2층'),
              )"
              :key="n.id"
              :class="{
                t_warning: n.temp.warning,
                h_waring: n.humi.warning,
                warning: n.temp.warning && n.humi.warning,
              }"
            >
              <h5>
                {{ n.temp.plc_location_name.split(`-`)[0] }}<br />
                {{
                  `${
                    n.temp.plc_location_name.split(`-`)[1] == undefined
                      ? ''
                      : `(${n.temp.plc_location_name.split(`-`)[1]})`
                  }`
                }}
              </h5>
              <div>
                <p>온도</p>
                <span>{{ n.temp.value }} ℃</span>
              </div>
              <div>
                <p>습도</p>
                <span>{{ n.humi.value }} %</span>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <h5>3층</h5>
          <ul class="th_card_wrap">
            <li
              class="th_card"
              :class="{ warning: item.warning }"
              v-for="(item, index) in filtered_temp_only_sensor_list.filter(x =>
                x.name.includes('3층'),
              )"
              :key="index"
            >
              <h5>
                {{ item.plc_sensor_name.split('온도센서')[0] }}
              </h5>
              <div>
                <p>온도</p>
                <span>{{ item.value }} ℃</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="monitor_wrap">
      <div class="monitor_head">
        <h2>실시간 금속검출기 현황</h2>
      </div>

      <div class="monitor_body">
        <div class="monitor_card good_card">
          <h3>인터페이스</h3>
          <p class="interface">
            {{ getCounterSensor[0][0].plc_sensor_name.split('금속검출기')[0] }}
          </p>
        </div>
        <div class="monitor_card good_card">
          <h3>양품</h3>
          <p>{{ $makeComma($makeNumber(getCounterSensor[1][0].value)) }}</p>
        </div>
        <div class="monitor_card bad_card">
          <h3>불량</h3>
          <p>{{ $makeComma($makeNumber(getCounterSensor[0][0].value)) }}</p>
        </div>
        <div class="monitor_card good_card">
          <h3>인터페이스</h3>
          <p class="interface">
            {{ getCounterSensor[0][1].plc_sensor_name.split('금속검출기')[0] }}
          </p>
        </div>
        <div class="monitor_card good_card">
          <h3>진행 수</h3>
          <p>
            {{ $makeComma($makeNumber(getCounterSensor[2][0].value)) }}
          </p>
        </div>
        <div class="monitor_card good_card">
          <h3>양품</h3>
          <p>{{ $makeComma($makeNumber(getCounterSensor[1][1].value)) }}</p>
        </div>
        <div class="monitor_card bad_card">
          <h3>불량</h3>
          <p>{{ $makeComma($makeNumber(getCounterSensor[0][1].value)) }}</p>
        </div>
        <div class="monitor_card good_card">
          <h3>인터페이스</h3>
          <p class="interface">
            {{ getCounterSensor[0][2].plc_sensor_name.split('금속검출기')[0] }}
          </p>
        </div>

        <div class="monitor_card good_card">
          <h3>양품</h3>
          <p>{{ $makeComma($makeNumber(getCounterSensor[1][2].value)) }}</p>
        </div>
        <div class="monitor_card bad_card">
          <h3>불량</h3>
          <p>{{ $makeComma($makeNumber(getCounterSensor[0][2].value)) }}</p>
        </div>
      </div>
    </div>
    <div class="card_wrap card_wrap_burner">
      <div class="card_head">
        <h5>가열기 온도 현황</h5>
      </div>
      <div class="card_body">
        <div>
          <h5>인터페이스 - 온도</h5>
          <ul class="th_card_wrap">
            <li
              class="th_card"
              v-for="n in filtered_temp_only_sensor_burner_list"
              :key="n.id"
              :class="{ warning: n.warning }"
            >
              <h5>
                {{
                  `${n.plc_sensor_name.split('인터페이스')[0]} - 
                    ${n.plc_sensor_name.split('인터페이스-')[1]}`
                }}
              </h5>
              <div>
                <p>온도</p>
                <span>{{ n.value }} ℃</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="monitor_wrap molding_wrap">
      <div class="monitor_head">
        <h2>실시간 가열성형기 현황</h2>
      </div>
      <div class="monitor_body">
        <div class="monitor_card good_card">
          <h3>인터페이스</h3>
          <p class="interface">
            {{
              getCounterSensorMolding[0][0].plc_sensor_name.split(
                '인터페이스',
              )[0]
            }}
          </p>
        </div>
        <div class="monitor_card good_card">
          <h3>온도</h3>
          <p>
            {{ $makeComma($makeNumber(getCounterSensorMolding[1][0].value)) }}
          </p>
        </div>
        <div class="monitor_card bad_card">
          <h3>RPM</h3>
          <p>
            {{ $makeComma($makeNumber(getCounterSensorMolding[0][0].value)) }}
          </p>
        </div>
        <div class="monitor_card good_card">
          <h3>인터페이스</h3>
          <p class="interface">
            {{
              getCounterSensorMolding[1][1].plc_sensor_name.split(
                '인터페이스',
              )[0]
            }}
          </p>
        </div>
        <div class="monitor_card good_card">
          <h3>온도</h3>
          <p>
            {{ $makeComma($makeNumber(getCounterSensorMolding[1][1].value)) }}
          </p>
        </div>
        <div class="monitor_card bad_card">
          <h3>RPM</h3>
          <p>
            {{ $makeComma($makeNumber(getCounterSensorMolding[0][1].value)) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import { mapGetters } from 'vuex';

export default {
  props: ['fullBtn'],
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensors: 'getPlcSensor',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      plc_sensor_logs_temp: 'getPlcSensorLogTemp',
      products: 'getProduct',
    }),
    filtered_temp_only_sensor_list() {
      if (this.plc_sensor_logs.length < 1 || this.plc_sensors.length < 1) {
        return [];
      } else {
        const plc_sensor_logs = this.lodash.clonedeep(this.plc_sensor_logs);
        const filter = this.lodash
          .clonedeep(this.plc_sensors)
          .filter(x => x.plc_sensor_type_id == 2)
          .map(x => {
            let log = plc_sensor_logs.find(y => y.plc_sensor_id == x.id);
            if (log == undefined) {
              console.log(plc_sensor_logs);
              log = this.plc_sensor_logs_temp.find(
                y => y.plc_sensor_id == x.id,
              );
            }
            x.value = log.value;
            x.plc_sensor_name = log.plc_sensor_name;
            x.create_time = log.create_time;

            if (x.value > x.max_value || x.value < x.min_value) {
              x.warning = true;
            } else {
              x.warning = false;
            }
            return x;
          });
        return filter.sort((a, b) => a.plc_sensor_name - b.plc_sensor_name);
      }
    },
    filtered_temp_humi_sensor_list() {
      if (this.plc_sensor_logs.length < 1 || this.plc_sensors.length < 1) {
        return [];
      } else {
        const filter = this.lodash
          .clonedeep(this.plc_sensors)
          .filter(x => x.plc_sensor_type_id == 1)
          .sort((a, b) => a.plc_sensor_name - b.plc_sensor_name);
        let tempFilter = filter.filter(x => x.plc_sensor_detail_type_id == 1);
        let humiFilter = filter.filter(x => x.plc_sensor_detail_type_id == 2);
        tempFilter = tempFilter
          .sort((a, b) => a.plc_sensor_name - b.plc_sensor_name)
          .map(x => {
            let log = this.plc_sensor_logs.find(y => y.plc_sensor_id == x.id);
            if (log == undefined) {
              console.log(this.plc_sensor_logs);
              log = this.plc_sensor_logs_temp.find(
                y => y.plc_sensor_id == x.id,
              );
            }
            x.plc_sensor_name = log.plc_sensor_name;
            x.plc_location_name = log.plc_location_name;
            x.value = log.value;
            x.create_time = log.create_time;
            if (x.value > x.max_value || x.value < x.min_value) {
              x.warning = true;
            } else {
              x.warning = false;
            }
            return x;
          });
        humiFilter = humiFilter
          .sort((a, b) => a.plc_sensor_name - b.plc_sensor_name)
          .map(x => {
            let log = this.plc_sensor_logs.find(y => y.plc_sensor_id == x.id);
            if (log == undefined) {
              log = this.plc_sensor_logs_temp.find(
                y => y.plc_sensor_id == x.id,
              );
            }
            x.plc_sensor_name = log.plc_sensor_name;
            x.plc_location_name = log.plc_location_name;
            x.value = log.value;
            x.create_time = log.create_time;
            if (x.value > x.max_value || x.value < x.min_value) {
              x.warning = true;
            } else {
              x.warning = false;
            }

            return x;
          });
        let returnData = [];
        tempFilter = tempFilter.sort(
          (a, b) => a.plc_location_id - b.plc_location_id,
        );
        humiFilter = humiFilter.sort(
          (a, b) => a.plc_location_id - b.plc_location_id,
        );
        humiFilter.forEach((el, index) => {
          let backgroundColor = ['#26ffff', '#bbd1ff'];
          if (el.warning || tempFilter[index].warning) {
            backgroundColor = ['#f18484', '#f1ddda'];
          }

          let data = {
            datasets: [
              {
                data: [el.value, 100 - el.value],
                fill: false,
                pointRadius: 4,
                backgroundColor: backgroundColor,
                lineTension: 0,
              },
            ],
          };
          returnData.push({
            temp: tempFilter[index],
            humi: humiFilter[index],
            graph_data: data,
          });
        });

        return returnData;
      }
    },
    filtered_temp_only_sensor_burner_list() {
      if (this.plc_sensor_logs.length < 1 || this.plc_sensors.length < 1) {
        return [];
      } else {
        const plc_sensor_logs = this.lodash.clonedeep(this.plc_sensor_logs);
        const filter = this.lodash
          .clonedeep(this.plc_sensors)
          .filter(x => x.plc_sensor_type_id == 7)
          .map(x => {
            let log = plc_sensor_logs.find(y => y.plc_sensor_id == x.id);
            if (log == undefined) {
              console.log(plc_sensor_logs);
              log = this.plc_sensor_logs_temp.find(
                y => y.plc_sensor_id == x.id,
              );
            }
            x.value = log.value;
            x.plc_sensor_name = log.plc_sensor_name;
            x.create_time = log.create_time;

            if (x.value > x.max_value || x.value < x.min_value) {
              x.warning = true;
            } else {
              x.warning = false;
            }
            return x;
          });
        return filter.sort(
          (a, b) =>
            a.plc_sensor_detail_type_id - b.plc_sensor_detail_type_id ||
            a.id - b.id,
        );
      }
    },
    getCounterSensor() {
      const failCountSensors = this.plc_sensor_logs
        .filter(
          x =>
            x.plc_sensor_name.includes('금속검출기') &&
            x.plc_sensor_detail_type_detail == 'fail count',
        )
        .sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
      const passCountSensors = this.plc_sensor_logs
        .filter(
          x =>
            x.plc_sensor_name.includes('금속검출기') &&
            x.plc_sensor_detail_type_detail == 'pass count',
        )
        .sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
      const totalCountSensors = this.plc_sensor_logs.filter(
        x =>
          x.plc_sensor_name.includes('금속검출기') &&
          x.plc_sensor_detail_type_detail == 'total count',
      );
      return [failCountSensors, passCountSensors, totalCountSensors];
    },
    getCounterSensorMolding() {
      const temperatureSensors = this.plc_sensor_logs
        .filter(
          x =>
            x.plc_sensor_name.includes('가열성형기') &&
            x.plc_sensor_detail_type_detail == 'temperature',
        )
        .sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
      const RPMSensors = this.plc_sensor_logs
        .filter(
          x =>
            x.plc_sensor_name.includes('가열성형기') &&
            x.plc_sensor_detail_type_detail == 'RPM',
        )
        .sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
      return [RPMSensors, temperatureSensors];
    },
  },
  methods: {
    yyyyMMddHHmmss(date) {
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '-' + (MM > 9 ? MM : '0' + MM),
        '-' + (dd > 9 ? dd : '0' + dd),
        ' (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },
    async fetch_plc_sensor_log_now() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_LOG_NOW')
        .then(response => {
          this.$store.commit(
            'setPlcSensorLogTemp',
            this.lodash.clonedeep(response.data.data_list),
          );
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            `온습도 정보를 불러오는 중 오류가 발생하였습니다.`,
          );
        })
        .finally(() => {});
    },
  },
  watch: {
    async timer(newValue) {
      if (newValue == 0 && this.tabIndex == 1) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
        await this.FETCH_NO_SPINNER(
          'FETCH_COUNTER_LOG_NOW',
          '금속검출기 점유현황',
        );

        this.timer = 10;
      }
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT', '제품');
    }

    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');

    this.timerCount = this.timerMax;
    this.fetch_plc_sensor_log_now();

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 6) {
        this.fetch_plc_sensor_log_now();
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
  },
};
</script>
